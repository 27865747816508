import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { FormProvider } from './contexts/FormContext';
import HomePage from './components/HomePage';
import ArtistForm from './components/ArtistForm';
import EngineerForm from './components/EngineerForm';
import TechSpecSheet from './components/TechSpecSheet';
import Footer from './components/Footer';

function AppContent() {
  const location = useLocation();
  const isTechSpecPage = location.pathname === '/tech-spec-sheet';

  return (
    <>
      <div className={`App ${isTechSpecPage ? 'tech-spec-page' : ''}`}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/artist" element={<ArtistForm />} />
          <Route path="/engineer" element={<EngineerForm />} />
          <Route path="/tech-spec-sheet" element={<TechSpecSheet />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <FormProvider>
        <AppContent />
      </FormProvider>
    </Router>
  );
}

export default App;
