import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ArtistForm from './ArtistForm';
import EngineerForm from './EngineerForm';
import logo from '../BacklineBoss-logo.svg'; // Adjust the path as needed

function HomePage() {
  const [formType, setFormType] = useState(() => {
    return localStorage.getItem('formType') || 'artist';
  });
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem('formData');
    return savedData ? JSON.parse(savedData) : null;
  });
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('formType', formType);
  }, [formType]);

  const handleFormSubmit = (data) => {
    setFormData(data);
    localStorage.setItem('formData', JSON.stringify(data));
    navigate('/spec-sheet', { state: { data } });
  };

  const handleFormTypeChange = (type) => {
    setFormType(type);
    setFormData(null);
    localStorage.removeItem('formData');
  };

  return (
    <div>
      <header className="app-header">
        <div className="logo-container">
          <h1 className="logo">
            <img src={logo} alt="BacklineBoss" />
            <span className="visually-hidden">BacklineBoss</span>
          </h1>
        </div>
        <p className="app-intro">
          Welcome to BacklineBoss - your ultimate tool for creating professional technical specification sheets. 
          Whether you're a band, solo artist or sound engineer, BacklineBoss simplifies the process of documenting 
          your technical requirements.
        </p>
      </header>
      <div className="form-type-selector">
        <button 
          onClick={() => handleFormTypeChange('artist')} 
          className={formType === 'artist' ? 'active' : ''}
        >
          Tech Spec
        </button>
        <button 
          onClick={() => handleFormTypeChange('engineer')} 
          className={formType === 'engineer' ? 'active' : ''}
        >
          Channel Plot
        </button>
      </div>
      <hr/>
      {formType === 'artist' ? (
        <ArtistForm onSubmit={handleFormSubmit} initialData={formData} />
      ) : (
        <EngineerForm onSubmit={handleFormSubmit} initialData={formData} />
      )}
    </div>
  );
}

export default HomePage;
