import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import html2pdf from 'html2pdf.js';

function TechSpecSheet() {
  const location = useLocation();
  const data = location.state?.data;

  const isEngineerData = data?.channels !== undefined;

  const handleDownloadPDF = () => {
    const element = document.getElementById('tech-spec-sheet');
    const opt = {
      margin:       10,
      filename:     `${data.bandName}_${isEngineerData ? 'channel_plot' : 'tech_spec'}.pdf`,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
    };
    html2pdf().set(opt).from(element).save();
  };

  if (!data) {
    return <div>No data available. Please fill out the form.</div>;
  }

  return (
    <div>
      <div id="tech-spec-sheet" className="tech-spec-sheet">
        <h1>{isEngineerData ? `Channel Plot for ${data.bandName}` : `Technical Specification for ${data.bandName}`}</h1>
        
        {isEngineerData ? (
          <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Channel</th>
                    <th>Name</th>
                    <th>Group</th>
                    <th>Mic Type</th>
                    <th>FX</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {data.channels.map((channel, index) => (
                    <tr key={index}>
                      <td>{channel.channel}</td>
                      <td>{channel.name}</td>
                      <td>{channel.group}</td>
                      <td>{channel.micType}</td>
                      <td>
                        {[...new Set(channel.fx)].join(', ')}
                        {channel.otherFx ? (channel.fx.length ? ', ' : '') + channel.otherFx : ''}
                      </td>
                      <td>{channel.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Instrument</th>
                  <th>Vocal Mic</th>
                  <th>DI Boxes</th>
                  <th>Instrument Mics</th>
                  <th>Power Supply</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {data.members.map((member, index) => (
                  <tr key={index}>
                    <td>{member.performerName}</td>
                    <td>{member.instrument}</td>
                    <td>{member.vMic ? 'Yes' : 'No'}</td>
                    <td>{member.di}</td>
                    <td>{member.instrumentMic}</td>
                    <td>{member.powerSupply ? 'Yes' : 'No'}</td>
                    <td>{member.notes}</td>
                  </tr>
                ))}
                <tr className="totals-row">
                  <td colSpan="2">Totals</td>
                  <td>{data.members.filter(m => m.vMic).length}</td>
                  <td>{data.members.reduce((total, m) => total + (parseInt(m.di) || 0), 0)}</td>
                  <td>{data.members.reduce((total, m) => total + (m.instrumentMic ? 1 : 0), 0)}</td>
                  <td>{data.members.filter(m => m.powerSupply).length}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          </>
        )}

        {data.additionalNotes && (
          <div className="additional-notes">
            <h2>Additional Notes</h2>
            <p>{data.additionalNotes}</p>
          </div>
        )}
      </div>
      <hr/>
      <div className="action-buttons">
        <button onClick={handleDownloadPDF} className="download-button">
          Download PDF
        </button>
        <Link to="/" className="back-button">
          Back to Form
        </Link>
      </div>
    </div>
  );
}

export default TechSpecSheet;
