import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContext } from '../contexts/FormContext';

function EngineerForm() {
  const { engineerFormData, setEngineerFormData } = useContext(FormContext);
  const [formData, setFormData] = useState(engineerFormData || {
    bandName: '',
    channels: [{ channel: 1, name: '', group: '', micType: '', fx: [], otherFx: '', notes: '' }],
    additionalNotes: ''
  });
  const [groupOptions, setGroupOptions] = useState(['Vocals', 'Drums', 'Guitar', 'Keys']);
  const [newGroup, setNewGroup] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (engineerFormData) {
      setFormData(engineerFormData);
    }
  }, [engineerFormData]);

  const renumberChannels = (channels) => {
    return channels.map((channel, index) => ({
      ...channel,
      channel: index + 1
    }));
  };

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => {
      let updatedFormData;
      if (index === undefined) {
        updatedFormData = { ...prevData, [name]: value };
      } else {
        const updatedChannels = [...prevData.channels];
        if (name === 'fx') {
          const fxSet = new Set(updatedChannels[index].fx);
          if (checked) {
            fxSet.add(value);
          } else {
            fxSet.delete(value);
          }
          updatedChannels[index] = {
            ...updatedChannels[index],
            fx: Array.from(fxSet)
          };
        } else {
          updatedChannels[index] = {
            ...updatedChannels[index],
            [name]: type === 'checkbox' ? checked : value
          };
        }
        updatedFormData = { ...prevData, channels: updatedChannels };
      }
      setEngineerFormData(updatedFormData);
      return updatedFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEngineerFormData(formData);
    navigate('/tech-spec-sheet', { state: { data: formData } });
  };

  const addChannel = () => {
    setFormData(prevData => {
      const newChannel = {
        channel: prevData.channels.length + 1,
        name: '',
        group: '',
        micType: '',
        fx: [],
        otherFx: '',
        notes: ''
      };
      const updatedChannels = [...prevData.channels, newChannel];
      const renumberedChannels = renumberChannels(updatedChannels);
      const updatedFormData = {
        ...prevData,
        channels: renumberedChannels
      };
      setEngineerFormData(updatedFormData);
      return updatedFormData;
    });
  };

  const removeChannel = (index) => {
    if (window.confirm('Are you sure you want to remove this channel?')) {
      setFormData(prevData => {
        if (prevData.channels.length > 1) {
          const updatedChannels = prevData.channels.filter((_, i) => i !== index);
          const renumberedChannels = renumberChannels(updatedChannels);
          const updatedFormData = {
            ...prevData,
            channels: renumberedChannels
          };
          setEngineerFormData(updatedFormData);
          return updatedFormData;
        }
        return prevData;
      });
    }
  };

  const addNewGroup = () => {
    if (newGroup && !groupOptions.includes(newGroup)) {
      setGroupOptions([...groupOptions, newGroup]);
      setNewGroup('');
    }
  };

  const fxOptions = ['Reverb', 'Delay', 'Compression', 'Other'];

  const handleReset = () => {
    if (window.confirm('Are you sure you want to reset the form? All unsaved data will be lost.')) {
      const emptyForm = {
        bandName: '',
        channels: [{ channel: 1, name: '', group: '', micType: '', fx: [], otherFx: '', notes: '' }],
        additionalNotes: ''
      };
      setFormData(emptyForm);
      setEngineerFormData(null);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <details className="form-instructions">
          <summary>Instructions (Click to expand)</summary>
          <div>
            <h3>What is a Channel Plot?</h3>
            <p>A Channel Plot is a detailed list of all the audio inputs for your performance, including information about microphones, instruments, and effects. It helps sound engineers set up the mixing console efficiently.</p>
            <h4>How to Use This Form:</h4>
            <ol>
              <li>Enter your band or artist name.</li>
              <li>Add details for each channel, including the instrument or source, microphone type, and any effects needed.</li>
              <li>Specify the group (e.g., drums, vocals) for each channel to help with organization.</li>
              <li>Add any additional notes about specific channels or overall setup requirements.</li>
              <li>Click "Generate Channel Plot" when you're done to create your technical document.</li>
            </ol>
            <p>This information helps ensure accurate sound setup and mix for your performance!</p>
          </div>
        </details>
        <hr/>
        <h2>Audio Channel Plot</h2>
        <div className="form-group">
          <label htmlFor="bandName">Band/Artist Name</label>
          <input
            type="text"
            id="bandName"
            name="bandName"
            value={formData.bandName}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        
        <div className="items-container">
          <h3>Channels</h3>
          {formData.channels.map((channel, index) => (
            <div key={index} className="item-inputs">
              <div className="item-number">{channel.channel}</div>
              <div className="input-group">
                <label htmlFor={`name-${index}`}>Name</label>
                <input
                  type="text"
                  id={`name-${index}`}
                  name="name"
                  value={channel.name}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div className="input-group">
                <label htmlFor={`group-${index}`}>Group</label>
                <select
                  id={`group-${index}`}
                  name="group"
                  value={channel.group}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option value="">Select a group</option>
                  {groupOptions.map((option, i) => (
                    <option key={i} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="input-group">
                <label htmlFor={`micType-${index}`}>Mic Type</label>
                <input
                  type="text"
                  id={`micType-${index}`}
                  name="micType"
                  value={channel.micType}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div className="checkbox-group">
                <div className="group-label">FX</div>
                <div className="checkbox-options">
                  {fxOptions.map((fx) => (
                    <div key={fx}>
                      <label>
                        <input
                          type="checkbox"
                          name="fx"
                          value={fx}
                          checked={channel.fx && channel.fx.includes(fx)}
                          onChange={(e) => handleChange(e, index)}
                        />
                        <span className="checkmark"></span>
                        {fx}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {channel.fx && channel.fx.includes('Other') && (
                <div className="input-group">
                  <label htmlFor={`otherFx-${index}`}>Other FX</label>
                  <input
                    type="text"
                    id={`otherFx-${index}`}
                    name="otherFx"
                    value={channel.otherFx}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              )}
              <div className="input-group">
                <label htmlFor={`notes-${index}`}>Notes</label>
                <input
                  type="text"
                  id={`notes-${index}`}
                  name="notes"
                  value={channel.notes}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              {formData.channels.length > 1 && (
                <button type="button" onClick={() => removeChannel(index)} className="remove-channel-btn">
                  Remove Channel
                </button>
              )}
            </div>
          ))}
        </div>
        
        <div className="form-actions">
          <button type="button" onClick={addChannel} className="add-item-btn">
            Add Channel
          </button>
          <button type="button" onClick={handleReset} className="reset-btn">
            Reset Form
          </button>
        </div>

        <hr/>

        <div className="form-group">
          <label htmlFor="newGroup">Add New Group</label>
          <p>Use this field to add custom group names to the list of groups.</p>
          <div className="input-group">
            <input
              type="text"
              id="newGroup"
              value={newGroup}
              onChange={(e) => setNewGroup(e.target.value)}
            />
            <button type="button" onClick={addNewGroup} className="add-item-btn">
              Add Group
            </button>
          </div>
        </div>

        <hr/>

        <div className="form-group">
          <label htmlFor="additionalNotes">Additional Notes</label>
          <p className="note-suggestion">
            Is there anything else we need to know? Perhaps you need a specific 
            reverb on the snare that sounds like it's echoing in the Grand Canyon, 
            or your guitarist insists their amp must be mic'd with a vintage 
            microphone from the 60s. Don't hold back on the audio quirks!
          </p>
          <textarea
            id="additionalNotes"
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <hr/>
        <div className="form-actions">
          <button type="submit" className="submit-btn">Generate Channel Plot</button>
        </div>
      </form>
    </div>
  );
}

export default EngineerForm;
