import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as PixolomewLogo } from '../assets/pixolomew-logo.svg';


function Footer() {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const isTechSpecPage = location.pathname === '/tech-spec-sheet';

  const containerClass = isTechSpecPage ? 'footer-container tech-spec' : 'footer-container';

  return (
    <footer className="bg-gray-100 py-4">
      <div className={containerClass}>
        <p className="text-sm text-gray-600">
          © {currentYear} BacklineBoss created by{' '}
          <a 
            href="https://pixolomew.digital" 
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex items-center"
          >
            <PixolomewLogo style={{ height: '1.5em', width: 'auto', verticalAlign: 'middle' }} />
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
