import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [artistFormData, setArtistFormData] = useState(null);
  const [engineerFormData, setEngineerFormData] = useState(null);

  return (
    <FormContext.Provider value={{ artistFormData, setArtistFormData, engineerFormData, setEngineerFormData }}>
      {children}
    </FormContext.Provider>
  );
};
