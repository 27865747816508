import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContext } from '../contexts/FormContext';

function ArtistForm() {
  const { artistFormData, setArtistFormData } = useContext(FormContext);
  const [formData, setFormData] = useState(artistFormData || {
    bandName: '',
    members: [{ performerName: '', instrument: '', vMic: false, di: '', instrumentMic: '', powerSupply: false, notes: '' }],
    additionalNotes: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (artistFormData) {
      setFormData(artistFormData);
    }
  }, [artistFormData]);

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => {
      let updatedFormData;
      if (index === undefined) {
        updatedFormData = { ...prevData, [name]: value };
      } else {
        const updatedMembers = [...prevData.members];
        updatedMembers[index] = {
          ...updatedMembers[index],
          [name]: type === 'checkbox' ? checked : value
        };
        updatedFormData = { ...prevData, members: updatedMembers };
      }
      setArtistFormData(updatedFormData);
      return updatedFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setArtistFormData(formData);
    navigate('/tech-spec-sheet', { state: { data: formData } });
  };

  const addMember = () => {
    setFormData(prevData => ({
      ...prevData,
      members: [...prevData.members, { performerName: '', instrument: '', vMic: false, di: '', instrumentMic: '', powerSupply: false, notes: '' }]
    }));
  };

  const removeMember = (index) => {
    if (window.confirm('Are you sure you want to remove this performer?')) {
      setFormData(prevData => {
        if (prevData.members.length > 1) {
          const updatedFormData = {
            ...prevData,
            members: prevData.members.filter((_, i) => i !== index)
          };
          setArtistFormData(updatedFormData);
          return updatedFormData;
        }
        return prevData;
      });
    }
  };

  const handleReset = () => {
    if (window.confirm('Are you sure you want to reset the form? All unsaved data will be lost.')) {
      const emptyForm = {
        bandName: '',
        members: [{ performerName: '', instrument: '', vMic: false, di: '', instrumentMic: '', powerSupply: false, notes: '' }],
        additionalNotes: ''
      };
      setFormData(emptyForm);
      setArtistFormData(null);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <details className="form-instructions">
          <summary>Instructions (Click to expand)</summary>
          <div>
            <h3>What is a Tech Spec?</h3>
            <p>A Technical Specification (Tech Spec) is a document that outlines your band's equipment and setup needs for a performance. It helps sound engineers and venue staff prepare for your show.</p>
            <h4>How to Use This Form:</h4>
            <ol>
              <li>Enter your band or artist name.</li>
              <li>Add details for each performer in your band, including their instrument and equipment needs.</li>
              <li>Specify if they need a vocal mic, DI boxes, instrument mics, or power supply.</li>
              <li>Add any additional notes about your setup or special requirements.</li>
              <li>Click "Generate Spec Sheet" when you're done to create your tech spec.</li>
            </ol>
            <p>This information helps ensure a smooth setup and the best possible sound for your performance!</p>
          </div>
        </details>
        <hr/>
        <h2>Artist Technical Specification</h2>
        <div className="form-group">
          <label htmlFor="bandName">Band/Artist Name</label>
          <input
            type="text"
            id="bandName"
            name="bandName"
            value={formData.bandName}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        
        <div className="members-container">
          <h3>Performers</h3>
          {formData.members.map((member, index) => (
            <div key={index} className="item-inputs">
              <div className="item-number">{index + 1}</div>
              <div className="input-group">
                <label htmlFor={`performerName-${index}`}>Performer Name</label>
                <input
                  type="text"
                  id={`performerName-${index}`}
                  name="performerName"
                  value={member.performerName}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div className="input-group">
                <label htmlFor={`instrument-${index}`}>Instrument</label>
                <input
                  type="text"
                  id={`instrument-${index}`}
                  name="instrument"
                  value={member.instrument}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              <div className="checkbox-row">
                <div className="checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name="vMic"
                      checked={member.vMic}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <span className="checkmark"></span>
                    Vocal Mic
                  </label>
                </div>
                <div className="checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name="powerSupply"
                      checked={member.powerSupply}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <span className="checkmark"></span>
                    Power Supply
                  </label>
                </div>
              </div>
              <div className="input-row">
                <div className="input-group">
                  <label htmlFor={`di-${index}`}>DI Boxes</label>
                  <input
                    type="number"
                    id={`di-${index}`}
                    name="di"
                    value={member.di}
                    onChange={(e) => handleChange(e, index)}
                    min="0"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor={`instrumentMic-${index}`}>Instrument Mics</label>
                  <input
                    type="number"
                    id={`instrumentMic-${index}`}
                    name="instrumentMic"
                    value={member.instrumentMic}
                    onChange={(e) => handleChange(e, index)}
                    min="0"
                  />
                </div>
              </div>
              <div className="input-group">
                <label htmlFor={`notes-${index}`}>Notes</label>
                <input
                  type="text"
                  id={`notes-${index}`}
                  name="notes"
                  value={member.notes}
                  onChange={(e) => handleChange(e, index)}
                />
              </div>
              {formData.members.length > 1 && (
                <button type="button" onClick={() => removeMember(index)} className="remove-item-btn">
                  Remove Performer
                </button>
              )}
            </div>
          ))}
        </div>
        
        <div className="form-actions">
          <button type="button" onClick={addMember} className="add-item-btn">
            Add Performer
          </button>
          <button type="button" onClick={handleReset} className="reset-btn">
            Reset Form
          </button>
        </div>
          <hr/>
        <div className="form-group">
          <label htmlFor="additionalNotes">Additional Notes</label>
          <p className="note-suggestion">
            Is there anything else we need to know? Maybe your drummer needs a 
            special cushion for their throne, or your bassist only plays well 
            when surrounded by rubber ducks. Don't be shy, let us know here...
          </p>
          <textarea
            id="additionalNotes"
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <hr/>
        <button type="submit" className="submit-btn">Generate Spec Sheet</button>
      </form>
    </div>
  );
}

export default ArtistForm;
